.expanded-check-container{
    display: flex;
    flex-direction: column;
    margin: 10px 20vw;
}

.inner-cl{
    margin-left: 40px;
    text-align: left;
}

.add-detail-con-div{
    margin-top: 20px;
    text-align: left;
}

.bottom-sec{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.submit-btn-con{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.outro-data{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: left;
}
.outro-data p{
    text-align: left;
    margin: 0;
}